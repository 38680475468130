import React, { Component } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo";



const pricingData = {
    prices: [{
        id:0,
        name:'Free',
        des: 'Try us out',
        price: '$0'
    }, {
        id:1,
        name:'Growth',
        des: 'Room to grow',
        price: '$10'

    }, {
        id:2,
        name:'ENTERPRISE',
        des: 'Maximum business value',
    }],

    things: [{
        name: 'Templates',
        data: [{
            id:0,
            value:'1',
            mvalue: '1 Template'
        },{
            id:1,
            value:'5',
            mvalue: '5 Templates'

        },{
            id:2,
            value:'Unlimited',
            mvalue: 'Unlimited Templates'

        }]
    }, {
        name: 'Emails Send',
        data: [{
            id:0,
            value:'100 Emails / month',
            mvalue:'Send 100 emails per month'
        },{
            id:1,
            value:'20000 Emails / month',
            mvalue:'Send 100000 emails per month'

        },{
            id:2,
            value:'100K Emails per month',
            mvalue:'Send 100K emails per month'

        }]
    }, {
        name: 'Email Editor',
        data: [{
            id:0,
            value:'Limited',
            mvalue:'Limited Editor Features'

        },{
            id:1,
            value:'All Blocks',
            mvalue:'Full Editor Features'

        },{
            id:2,
            value:'All Blocks',
            mvalue:'Full Editor Features'

        }]
    }, {
        name: 'AWS Statistics History',
        data: [{
            id:0,
            value:'15 days '
        },{
            id:1,
            value:'1 months'
        },{
            id:2,
            value:'3 months'
        }]
    }]
}


const supportEmail = "athul@emailkick.com"

const FAQList = [{
    title:'Billing Cycle',
    des: 'Your billing cycle starts on the day you signed up for a paid plan and renews monthly. Plan usage limits are reset on this day.'
}, {
    title:'Automatic Billing',
    des: 'Your card details are securely stored with Stripe and your card is automatically billed every month as per your plan charges.'
}, {
    title:'Moving from Free to Paid Plan',
    des: 'Your card is immediately charged as per the plan amount.'
}, {
    title:'Moving from Low to High Priced Plan',
    des: 'Prorated balance if any is deducted from the High Price Plan you choose and the balance is charged immediately to your card.'
}, {
    title:'Moving from High to Low Priced Plan',
    des: `Prorated balance if any will be refunded to you within 30 days subject to meeting conditions in Points 8 & 9 below. Write to ${supportEmail} for a quicker refund.`
}, {
    title:'Moving from a Paid to Free Plan',
    des: `Prorated balance if any will be refunded to you within 30 days subject to meeting conditions in Points 8 & 9 below. Write to ${supportEmail} for a quicker refund.`
}, {
    title:'No longer using EmailKick?',
    des: `Switch to the Free Plan if you are paying for but no longer wish to use EmailKick. Write to ${supportEmail} asking for a refund. Prorated balance if any will be refunded within 30 days.`
}, {
    title:'PLAN ABUSE',
    des: `You will not be refunded if you switch to a High Priced Plan, send more than 15,000 emails, and then switch back to a Low Priced or Free Plan.`
}, {
    title:'SPAM SENDERS',
    des: `You will not be refunded if your account is marked as a Spammer (Bounce + Unsubscribe + Complaint Rate >= 5%). `
} ]
export default class pricing extends Component {
    render() {
        return (
            <Layout>
                <SEO title="Pricing" />
                <div className=" pt-24">
                    <div className="max-width-4 mx-auto">
                        <div className="col-12 xs-hide sm-hide">

                            <div className="flex mxn1 display items-end">
                                <div className="col-12 md-col-4 lg-col-4 px1 flex">
                                    <div className="col-12">
                                        <h2 className="huge strong display font-bold block line-height-3">Simple pricing</h2>
                                    </div>
                                </div>
                                <div className="col-3 px1 flex flex-wrap">
                                    <div className="  rounded-huge-top pt3 px2 flex-auto flex relative overflow-hidden z1">
                                        <div className="flex-auto z2">
                                            <div className="center caps strong display huge mb-half col-12">Free</div>
                                            <div className="center quiet col-12">Try us out</div>
                                        </div>
                                    </div>
                                   
                                </div>
                                
                                <div className="col-3 px1 flex flex-wrap">
                                    <div className=" yellowLight2 rounded-huge-top pt3 px2 flex-auto flex relative overflow-hidden z1">
                                        <div className="flex-auto z2">
                                            <div className="center caps strong display huge mb-half col-12">Pro</div>
                                            <div   className="center quiet col-12">Room to grow</div>
                                        </div>
                                    </div>
                                   
                                </div>
                               
                                <div className="col-3 px1 flex flex-wrap">
                                    <div className="center caps strong display huge mb-half col-12">Growth</div>
                                    <div className="center quiet col-12">Maximum business value</div>
                                </div>
                            </div>


                            <div className="flex mxn1 display">
                                <div className="col-12 md-col-4 lg-col-4 px1 flex items-center">
                                    {/* <div className="col-12">
                                        <div className="flex items-center xs-justify-center sm-justify-center mb1 pointer display quiet link-unquiet animate">
                                            <div className="mr1 big quiet">Pay annually</div>
                                            <div className="pill flex flex-none animate justify-start darken4" role="checkbox" aria-checked="false" aria-readonly="false">
                                                <div className="white circle flex-none"></div>
                                            </div>
                                            <div className="ml1 big quiet">Pay monthly</div>
                                        </div>
                                    </div> */}
                                    <p className="quiet col-12">Find a plan that’s right for you</p>
                                </div>

                                    <div className="col-3 px1 flex">
                                        <div className="rounded-huge-top pt3 px2 flex-auto flex relative overflow-hidden z1">
                                            <div className="flex-auto z2">
                                                <h1 className="center huge display strong mb-half line-height-3 pt1">$0</h1>
                                                <div className="quiet center line-height-4">per month</div>
                                                <div className="quiet center line-height-4 nowrap">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="col-12 pt1"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3 px1 flex">
                                        <div className="yellowLight2 pt3 px2 flex-auto flex relative overflow-hidden z1">
                                            <div className="flex-auto z2">
                                                <h1 className="center huge display strong mb-half line-height-3 pt1">$10</h1>
                                                <div className="quiet center line-height-4">per month</div>
                                                <div className="quiet center line-height-4 nowrap">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="col-12 pt1"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3 px1 flex">
                                        <div className=" pt3 px2 flex-auto flex relative overflow-hidden z1">
                                            <div className="flex-auto z2">
                                                <h1 className="center huge display strong mb-half line-height-3 pt1">$48</h1>
                                                <div className="quiet center line-height-4">per month</div>
                                                <div className="quiet center line-height-4 nowrap">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="col-12 pt1"></div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col-3 px1 flex">
                                        <div className="rounded-huge-top pt3 px2 flex-auto flex relative overflow-hidden z1">
                                            <div className="flex-auto z2">
                                                <div className="quiet center line-height-4 nowrap">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <h3 className="center huge display strong mb-half line-height-3 pt1">Contact Us</h3>
                                                <div className="quiet center line-height-4 nowrap">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="col-12 pt1"></div>
                                            </div>
                                        </div>
                                    </div> */}


                                </div>




                                <div className='col-12'>
                                    { pricingData.things.map(d => (
                                        <div className="flex mxn1 display hover-container relative">
                                            <div className="absolute top-0 bottom-0 parent-hover-darken1 noevents z1 rounded-big" style={{ left: '-12px', right:'-12px'}}></div>
                                            <div className="col-12 md-col-4 lg-col-4 px1 flex">
                                                <div className="flex-auto py1 huge py2">
                                                    <a className="pointer" href={`#${d.name}`}>{d.name}</a>
                                                </div>
                                            </div>

                                            { d.data.map((data, index) => (
                                                <div className={`col-3 px1 flex `} key={index}>
                                                    <div className={`flex items-center justify-center flex-auto huge py2 ${ index == 1 ? 'yellowLight2' : ''} `}>{data.value}</div>
                                                </div>
                                            ))}

                                            

                                        </div>
                                    )) }
                                    
                                </div>

                                <div class="flex mxn1 display">
                                    <div class="col-12 md-col-4 lg-col-4 px1 flex"></div>
                                   
                                    <div class="col-3 px1 flex">
                                        <div class="flex-auto rounded-huge-bottom p2 center">
                                            <div class="lg-center md-center huge strong py1">
                                                <a href="https://app.emailkick.com/register" class="link understroke pointer text-blue">Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 px1 flex">
                                        <div class="flex-auto rounded-huge-bottom p2 center yellowLight2">
                                            <div class="lg-center md-center huge strong py1">
                                                <a  href="https://app.emailkick.com/register?p=p" class="link understroke pointer text-blue">Get started for Free</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-3 px1 flex">
                                        <div class="flex-auto rounded-huge-bottom p2 center ">
                                            <div class="lg-center md-center huge strong py1">
                                                <a  href="https://app.emailkick.com/register?p=en" class="link understroke pointer text-blue">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                              

                            </div>


                            

                            <div className="md-hide lg-hide col-12">

                                <div class="center mb3">
                                    <div class="col-12 md-col-4 lg-col-4 px1 flex">
                                        <div class="col-12">
                                            <h2 class="huge strong display block line-height-3">Simple pricing</h2>
                                        </div>
                                    </div>
                                </div>



                                {
                                    pricingData.prices.map((p, index) => (
                                            <div class={`rounded-huge mb2 px2 pt3 pb2 display ${index == 1 ? 'yellowLight1' : ''}`}>
                                                <div class="caps strong big mb-half">{p.name}</div>
                                                <div class="huge mb1 quiet">{p.des}</div>
                                                <div class="flex items-center mb1">
                                                    <h1 class="huge display strong mb-half line-height-1">{p.price}</h1>
                                                    { p.price ? 
                                                    <div class="flex-none ml1 quiet big">
                                                        <div>per month</div>
                                                    </div> : <h3 class=" my2 huge display strong mb-half line-height-1">Contact Us</h3> }
                                                </div>
                                                <div class="my2">
                                                    { pricingData.things.map( (th, ind) => (<div class="huge mb-half strong mb1">{th.data[index].mvalue}</div>) ) }
                                                </div>
                                                <div className="my2">
                                                    {
                                                        p.price ? <div class="lg-center md-center huge strong py1">
                                                            <a href="https://app.emailkick.com/register" class="link understroke pointer text-blue">Get Started</a></div>
                                                       : <div class="lg-center md-center huge strong py1">
                                                       <a href="https://app.emailkick.com/register" class="link understroke pointer text-blue">Learn More</a></div>
                                                    }
                                                 </div> 
                                            </div>
                                    ))
                                }
                                



                            </div>

                            




                        </div>

                        <div className="text-center py-8 mt-12 text-lg font-medium text-gray-700">
                            <span>Want a custom plan? <a class="text-blue-800 hover:text-blue-900 hover:underline" href="#">Contact us</a></span>
                        </div>

                        <div className="max-width-3 mx-auto mt-12">
                            {/* <div className="w-full rounded-md  p-2 flex justify-between">
                                <div className="w-full pr-4">
                                    <div className=" rounded-md bg-blue-50 p-2">
                                        <h4 className="text-lg text-blue-800 font-medium">Custom Plan</h4>
                                        <small className="text-sm text-gray-500">Get unlimited everything</small>
                                        <ul className="grid gap-2">
                                            <li>Unlimited Emails</li>
                                            <li>Unlimited Templates</li>
                                            <li>Unlimited Send History</li>
                                        </ul>
                                    </div>
                                    
                                </div>
                                
                            </div> */}
                        </div>
                        
                        <div className="bg-gray-50 mt-24 py-4 border-t border-gray-100">
                        
                            <div className="max-width-4 mx-auto">
                                <div className="md-col-7 col-12 mt4">
                                        <h3 className="mb-2">Frequently Asked Questions</h3>
                                            { FAQList.map(f => (
                                                <div className="col-12 py2 py2 border-bottom-1">
                                                    <h4 className="py-2 border-b border-gray-200"> { f.title }</h4>
                                                    <p className="quiet line-height-5 text-sm mt-2"> { f.des }</p>
                                                </div>
                                            )) }
                                            
                                </div>
                            </div>
                        </div>

                        <div className="mt-24">
                            <div className="max-w-3xl mx-auto text-center bg-blue-50 px-2 py-12 rounded-lg shadow-sm ">
                                <h2 className="text-3xl font-medium">Ready to email?</h2>
                                <p className="mt-2 text-lg text-gray-500">Get started for free, upgrade later in our application.</p>
                                <div className="cta-bottom mt3">
                                    <a href="http://app.emailkick.com/register" className=" inline-block bg-red-500 hover:bg-red-600 text-white strong px-8 py-3 text-lg font-medium rounded-xl  duration-100 shadow-lg">Get started</a></div>
                            </div>
                        </div>
                        


                    </div>
             
            </Layout>
        )
    }
}
